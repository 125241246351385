
.slide{
    width: 100%;
    padding-top: 155px;
}

.title h2{
    text-align: left;
    margin-left: 3%;
}

.pgraph{
    width: 50%;
    margin-bottom: 30%;
}

.pgraph h5{
    font-weight: 100;
    text-align: left;
    margin-left: 9%;
}