#about {
    /* Styles for the h3 element inside the container */
    color: #5CFF6D;
    width: auto;
    display: inline-block;
}

#about:hover {
    color: #EDEDED; /* Scale up the h3 element inside the container on hover */
    cursor: pointer;
}

.paragraph {
    width: 400px; /* Adjusted width to accommodate the heading */
    min-width: 400px;
    margin: 0;
}
.socials{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 9%;
    margin-left: 1%;
}

.socials span{
    margin-left: 50%;
    font-size: 0; /* Set font size to 0 to hide the "#" tag */
}

.paragraph h5 {
    font-size: 12pt;
    line-height: 17pt;
    font-weight: 200;
    text-align: left;
    margin: 0;
    margin-top: 2%;
}

.heading img{

    width: 30px;
    height: auto;
}


.heading {
    width: fit-content;
    margin-left: 10%; /* Adjusted margin for better spacing */
    display: inline-flex;
    flex-direction: column;
     /* Align items to the top of the heading container */
    align-items: flex-start; /* Align items to the start of the heading container */
    margin-right:2%;
    min-width: 230px;
}

.heading h1 {
    text-align: left;
    line-height: 40pt;
    display: inline-block;
    margin: 0; /* Reset margin to remove extra space */
    top:0;
}

.abt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top; /* Align items horizontally in the abt container */
    margin-left: 2%;
    margin-top: 155px;
}

@media (max-width: 431px) {
    .heading{
        display: none;
        
    }
    .socials{
        display: none;
    }
    .abt{
        margin: 0%;
    }
    .paragraph{
        width: 100%;
        min-width: 0;
    }
    .paragraph h5{
        font-size: 12pt;
        line-height: 16pt;
        margin-bottom: 70%;

    }
  }