.picture img{
    width: 80%;

}

.picture{
    left: 0;
}

.video{
    padding-bottom: 30%;
}