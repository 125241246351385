

.projectImageContainer {
    perspective: 5500px; /* Add perspective for 3D effect */
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: space-between; /* Space items evenly */
}

.projectImage {
    width: auto; /* Allow width to adjust based on image size */
    height: auto; /* Allow height to adjust based on image size */ /* Set maximum width to 30% of the container */
    /* Set maximum height for consistency */
    position: relative; /* Position relative for absolutely positioned children */
    overflow: hidden; /* Hide overflow */
    border-radius: 19px; /* Add rounded corners */
    cursor: pointer;
    transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1); /* Smooth transition */
    transform-style: preserve-3d; /* Preserve 3D transformations */
}



.projectImage img {
    display: block; /* Make the image a block-level element */
    width: 100%; /* Make the image fill the container */
    height: auto; /* Allow height to adjust based on width */
    object-fit: cover; /* Crop the image to cover the container */
}



.projectImage.rotating {
    transition: transform 0.3s cubic-bezier(0.1, -0.6, 0.2, 0); /* Ease-out transition */
}




.projectComp {
    width: auto; /* Adjust width to fit content */
    height: auto;
    padding-left: 5%; /* Add padding to the left */
    padding-right: 5%; /* Add padding to the right */
    padding-bottom: 12%;
}

.projectComp:hover .projectImage {
    transform: perspective(400px) rotateY(-8deg) scale(95%) translate(-5px) ;
   
    border: 1.2pt solid #EDEDED; /* Add white border */
    box-sizing: border-box; /* Include border thickness in the total width and height */
}


  

