.homeBody {
  margin-bottom: 40%;
  display: flex;
  flex-direction: row;
  margin-top: 155px;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align contents to the start of the flex container */
  width: fit-content; /* Adjust width dynamically to fit contents */
}

  @media (max-width: 730px) {
    .projecta {
      width: 100%;
      height:auto; /* Set width to 100% for screens 430 pixels and below */
      margin-left: 7%;
      margin-right: 7%;
    }
  }
  @media (min-width: 740px){

    .homeBody{
      margin-right: 0%;
      margin-left: 6%;
  
    }
  .projecta {
    min-width: 50px;
    width: 39%; /* Default width */
    height: auto;
  }
  .projecta.large {
    width: 50%; /* Width when image has a certain aspect ratio */
  }
  }
  @media (min-width: 1100px){

    .homeBody{
      margin-right: 4%;
 
    }
  .projecta {
    min-width: 50px;
    width: 26%; /* Default width */
    height: auto;
}
.projecta.large {
    width: 36%; /* Width when image has a certain aspect ratio */
}
  }



