/* Common styles */

body {
  background-color: black; /* Set the background color of the entire page */
}

::selection {
  background-color: #5CFF6D; /* Change the background color */
  color: #000000; /* Change the text color */
}


.m-plus1 {
  font-family: 'M PLUS 1', sans-serif; /* Specify the font family */
  font-optical-sizing: auto; /* Set the optical sizing */
  /* Define additional font properties as needed */
}

a {
  text-decoration: none; /* Remove underline */
}


h1 {
  /* Styles for h1 */
  font-family: 'M PLUS 1';
  font-size: 30pt; /* Example font size */
  font-weight: 450; /* Example font weight */
  color: #f0f0f0; /* Example color */
}

h2 {
  /* Styles for h2 */
  font-family: 'M PLUS 1';
  font-size: 15pt; /* Example font size */
  font-weight: 450; /* Example font weight */
  color: #f0f0f0; /* Example color */
}

h3 {
  /* Styles for h3 */
  font-family: 'M PLUS 1';
  font-size: 13.5pt; /* Example font size */
  font-weight: 450; /* Example font weight */
  color: #f0f0f0; /* Example color */
  line-height: 20px;
  line-break: normal;
}


.subtext {
  font-size: 9pt;
}

p{
  font-family: 'M PLUS 1';
  font-size: 11pt; /* Example font size */
  font-weight: 450; /* Example font weight */
  color: #f0f0f0; /* Example color */
}

.App {
  background-color: black;
  display: flex; /* Ensure flex layout for children */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  width: 100vw; /* Set width to 100% of viewport width */
  height: 100vh; /* Set height to 100% of viewport height */
  display: flex;
  flex-direction: row;

  font-size: calc(10px + 2vmin);
  color: white;
}

.mobile-header {
  position: fixed; /* Fix the position of the mobile header */
  top: 0; /* Stick to the top of the screen */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items horizontally */
  justify-content: space-between; /* Add space between logo and menu button */
  margin-bottom: 5%;
}

.mobile-logo img {
  width: 60px;
  height: 60px;
 /* Add margin for spacing */
}

.menu-button {
  margin-top: 8%;
  margin-right: 10%; /* Add margin for spacing */
}

.mobile-logo{
  margin-top: 8%;
  margin-left: 10%;
}
.menu-button img {
  width: 25px;
  height: 25px;
}


@media (min-width: 431px){
  
  .App-header .projectImage {
    width: 100%;
    height: auto;
  }

  .mobile-footer{
    display: none;
  }

/* Styles specific to non-touch-enabled devices (desktop) */
 .App-header {
  display: flex; /* Enable flex layout */
}
.main-wrapper {
  margin-left: 18%;
}

.mobile-header{
  display: none;
}


 .App-header .sidebar-footer-wrapper {
  flex-direction: column;
  position:absolute;
  background-color: black;
  max-width: 300px;
  bottom:0%;
  padding-left:40px;
  padding-bottom: 40px;
  padding-top: auto; /* Position the sidebar footer relative to the sidebar wrapper */
   /* Align the sidebar footer to the bottom of the sidebar wrapper */
   /* Align the sidebar footer to the left of the sidebar wrapper */
  width: 100%; /* Make the sidebar footer occupy the full width of the sidebar wrapper */
   /* Example background color */
  /* Example text color */
}


 .App-header .sidebar-wrapper {
  overflow: hidden;
  background-color: black;
  position: fixed; /* Fix the sidebar position */
  height: 100vh;
  left: -2px; /* Position it at the left */
  width: 17%; /* Set the width */
  min-width: 230px; /* Minimum width of 100 pixels */ /* White outline */
  
}


/* Main content */


 .App-header .main-wrapper p {
  color: white; /* Change the text color to white */
  font-size: 100px; /* Adjust the font size */
  line-height: 1.5; /* Set the line height */
  margin: 20px; /* Add margin for spacing */
}

/* Media query for smaller screens */
@media (max-width: 1200px) {
   .App-header .sidebar-wrapper {
    flex: 0 0 auto; /* Allow the sidebar to shrink as needed */
    min-width: 200px; /* Reset minimum width */
    margin-right: 10px; /* Add margin for spacing */
  }
  
  body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

   .App-header .main-wrapper {
    width: calc(100% - 200px); /* Set width to fill remaining space after sidebar width and margin */
    margin-left: 200px; /* Adjust margin to prevent overlap */
    display: flex;
    flex-direction: column;
    min-width: 300px; /* Set minimum width */
    overflow-x: hidden;
  }

   .App-header .projectImage {
    width: 100%;
    height: auto;
  }
}
}

@media (max-width: 432px) {
  .main-wrapper {
    margin-top: 30%;
  } 

  .mobile-footer{
    position: fixed;
    bottom: 0;
    margin-bottom: 0%;
    margin-left: 3%;
    margin-right: 3%;
    width: 75%;
    background-color: black;
    }

  .App-header .projectImage {
    width: 100%;
  }

  .App-header {
    flex-direction: column; /* Arrange header items vertically */
  }

  .sidebar-wrapper {
     display:none;
  }

  .main-wrapper {
    width: 100%; /* Main content takes full width */
  }
}

.main-wrapper {
  background-color: black;
  color: white;
  width: 100%;
  text-align: center;
  overflow-y: auto; /* Enable vertical scrolling when content exceeds wrapper height */
  max-height: 100vh; /* Limit maximum height to viewport height */
  overflow-x: hidden; /* Hide horizontal overflow */
 
}



.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

