
.logo2 img{
    height: 80px;
    width: auto;
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 25px;
    
}

.arrow-wrapper img:hover {
    transform: scale(1.2); /* Scale up the arrow */
    cursor: pointer; /* Change cursor to pointer */
  }

.navigation-wrapper.invisible {
    display: none;
  }
  
  /* Show the arrow button when pageState is 'about' or 'project' */
  .arrow-wrapper.visible {
    display: inline-block; /* You may need to adjust the positioning and styling */
    margin-left: 15%;

  }

  .sidebar-footer-wrapper .artButton img{
    width: 135px;
    padding-bottom: 1px;
    height: auto;
  }

  .sidebar-footer-wrapper .aboutme img{
    width: 105px;
    padding-bottom: 1px;
    height: auto;
  }
  .topButtons{
    padding-bottom: 5px;
  }

  .arrow-wrapper img{
    width: 35px;
    height: auto;
    padding-left: 4px;

  }
  
  
  
  /* Hide the arrow button by default */
  .arrow-wrapper {
    display: none;
  }

.sidebar-footer-wrapper {
    display: block; /* Ensure the sidebar footer is initially visible */
  }
  
