

.project3page{
    padding-top: 20%;
    
}

.project3page h3{
    text-align: left;
}

.nameTitle{
    padding-top: 4%;
    margin-left: 3%;
}

.nameTitle h3{
    text-align: left;
    font-size: 15pt;
    
}