


.nav{
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    
}

.large-button-container{
    display: inline-flex;
    align-items: center;
    height: 30px;
}

.large-button-container h3{
    line-height: 0px;
}
.large-button-container img {
    left:0;
    margin-right: 8px; /* Adjust the margin as needed */
    
  }
